.centerFlexedFullHeight
{
    display:'flex';
    flex:1;
    height:'100%';
    align-items:'center';
    flex-direction:'column';
    justify-content:'center';
}
.topMargin10
{
    margin-top: 5px;
}
