.loader {
  
    border: 10px solid hsl(0, 0%, 95%);
    border-top: 10px solid #4db6ac;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0px auto;
    animation: spin 1s linear infinite;
  }
 .spinner{
  border: 1px solid hsl(0, 0%, 95%);
  border-top: 1px solid #f3f9f8;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0px auto;
  animation: spin 1s linear infinite;
 }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 