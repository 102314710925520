.card {
    display: flex;
    flex-direction: column;
    
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 95%;
    max-width: 500px;
    box-sizing: 'border-box' 
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  .progress-bar-container {
    width: 100%;
    background-color: #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
  }
  
  .progress-bar {
    background: linear-gradient(90deg, #fe4040, #ffb3b3);
    height: 20px;
    line-height: 20px;
    color: white;
    text-align: center;
    border-radius: 8px;
    transition: width 1.5s ease-in-out;
  }