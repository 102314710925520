.image-fade-in {
    opacity: 0;
    transform: translateX(25px);
    transition: opacity 0.6s ease-in-out, transform 0.8s ease-in-out;
  }
  
  .image-visible {
    opacity: 1;
    transform: translateX(0);
  }
  