.App {
  display: flex;

 
}
.appBody{
  display: flex;

  flex-direction: column;
  background-color: black;
  color: antiquewhite;
  text-align:center;
  justify-content: center;
   height:100vh
}
.appHeader1
{
  background-color: #fffafa;
 
  display: flex;
 
  width: 100%;
  max-width:500px;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
}
.appHeader
{
  background-color: #6e0c71;
  width: 100%;
  max-width: '500px';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
   border:'2px solid black';
   margin: '0px auto';
   height:60px;
   
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
 
   
   position: fixed;
   border:'2px solid black';
  
}
.appLogo
{
  
 
  
}
.sideNavigation
{
 
  position: fixed;
  top:50px;
  height:100%;
 
  z-index: 1000;
  background-color:#f8f2f2;
  background-repeat: no-repeat;
  background-size: cover;
  width:100%;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  align-self: center;
  padding:10px;
  text-align: left;

  
  
}
.applogoother{
  width:50px;
  height:50px;
  margin-left:10px;
}

