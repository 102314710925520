/* Overall container to center the content with max-width of 500px */
.app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self:'center';
   padding: 20px;
     width: 100%;
    background: #f28a4b;
    margin-top: 60px;
  
  }
  
  .content {
    display: flex;
    flex-direction: column; /* Stacks the item and checkout forms vertically */
    gap: 30px; /* Adds spacing between the image and the form */
    max-width: 500px; /* Ensures content does not exceed 500px width */
    width: 100%;
    margin-top: 50;
   
  }
  .more-from-store-btn {
  
    
    padding: 8px 12px;
    background-color: #fe4040;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
   
  }
  
  .more-from-store-btn:hover {
    background-color: #d93636;
  }
  
  
  .item-container {
    display: flex;
    flex-direction: column; /* Ensure the item details are stacked vertically */
    align-items: center; /* Centers the content horizontally */
    background-color: white;
    max-width: 500px;
    margin:'0px auto';
    justify-content: center;
  }
  
  .item-image img {
    width: 70%; /* Ensures the image takes full width of the container */
    height: auto;
    margin-top: 20px;
  }
  
  .checkout {
    background: #2f2c33;
    padding: 30px;
    color: white;
    border-radius: 8px; /* Added rounded corners for a soft look */
  }
  
  .checkout-container {
    display: flex;
    flex-direction: column; /* Stacks the form fields vertically */
    align-items: center; /* Centers the form fields */
  }
  
/* Checkout Button */
.checkout-btn {
    background: linear-gradient(135deg, #019875, #029875); /* Added angle for better effect */
    border-radius: 8px; /* More rounded corners */
    width: 100%;
 
    height: 45px; /* Increased height for better visibility */
    color: white;
    font-size: 18px; /* Slightly larger text for better readability */
    font-weight: bold; /* Makes the text more prominent */
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 20px;
    transition: all 0.3s ease; /* Smooth transition for background, scale, and color */
    text-align: center;
    padding: 10px; /* Extra padding for better touch interaction */
    border: 'none';
    outline: 'none';
  }
  
  .checkout-btn:hover {
    background: linear-gradient(135deg, #028865, #01a984); /* Slight color change on hover */
    transform: scale(1.05); /* Slightly grow the button on hover */
  }
  
  .checkout-btn:focus {
    outline: none; /* Remove outline on focus */
    box-shadow: 0 0 5px 2px rgba(1, 152, 117, 0.5); /* Focus outline with a matching color */
  }
  
  /* Input Field */
  .input-field {
    display: flex;
    align-items: center;
    border: 1px solid #ccc; /* Softer border color */
    border-radius: 8px; /* More rounded corners */
    
    width: 100%;
   
    font-size: 16px; /* Slightly larger font for readability */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  }
  
  .input-field input {
    width: 100%;
    border: none;
    outline: none;
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .input-field input:focus {
    border-color: #019875; /* Changes border color when focused */
    box-shadow: 0 0 5px rgba(1, 152, 117, 0.3); /* Adds subtle shadow when focused */
  }
  
  .input-field img {
    margin-left: 10px;
    height: 20px;
    width: auto;
  }
  
  
  